import React, {useEffect, useState} from 'react';
import * as Yup from 'yup';
import {Field, Form, Formik} from "formik";
import {Button, FormControl, TextField} from "@mui/material";
import {Container, ErrorText, FieldContainer, FormContainer, theme, Title} from "../../styles/styles";
import {ThemeProvider} from "@mui/system";
import axios from "axios";
import Img from "gatsby-image";
import {graphql, StaticQuery, Link} from "gatsby";
import "normalize.css";
import "../../styles/main.css";
import "yup-phone";

import styled from "styled-components";;

const BackButtonForm = styled(Link)`
  display: block;
  width: 1rem;
  margin-left: 3rem;
`

const GroupFormSchema = Yup.object().shape({
  first_name: Yup.string().required('Pole wymagane'),
  last_name: Yup.string().required('Pole wymagane'),
  email: Yup.string()
    .email('Wprowadź poprawny email')
    .required('Pole wymagane'),
  phone: Yup.string().phone("PL", true, "Niepoprawny numer telefonu.").required('Pole wymagane.'),
});


export const GroupFormPage = (props) => {
  const groupId = props?.params?.['groupId'];
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const [hosts, setHosts] = useState([]);

  useEffect(() => {
    if (groupId) {
      axios.get(`https://www.grupy-radosci.pl/api/v1/groups/${groupId}/users`).then((data) => {
        if (data?.data) {
          setHosts(data.data);
        }
      })
    }
  }, [groupId])

  return (<StaticQuery
    query={graphql`
        query FormQuery {
          backArrow: file(relativePath: { eq: "back-arrow.png" }) {
            childImageSharp {
              fluid(maxWidth: 40, quality: 100) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      `}
    render={(data) => (<ThemeProvider theme={theme}>
      <Container>
        <BackButtonForm to={'/'}>
          <Img fluid={data.backArrow.childImageSharp.fluid}/>
        </BackButtonForm>
        {groupId ? (<>
          {success ? (<Title>
            Formularz został wysłany. Oczekuj wiadomości e-mail z akceptacją zgłoszenia
          </Title>) : (<>
            {error ? (<Title>
              {disabled ? (<>
                Zapisy na najbliższe spotkanie tej grupy zostały zamknięte. Spróbuj ponownie później
              </>) : (<>
                Wysłanie formularza nie powiodło się. Spróbuj ponownie później
              </>)}
            </Title>) : (

              <Title>
                <>
                  Zapisz się na Grupę Radości do:
                  <br/>
                  {hosts.map((host) => (<>
                    {host.first_name}
                    {' '}
                    {host.last_name}
                    <br/>
                  </>))}
                </>
              </Title>)}
          </>)}
        </>) : (<h3>
          Nie znaleziono takiej grupy
        </h3>)}
        {(groupId && !error && !success) && (<Formik
          initialValues={{
            group_id: groupId, first_name: '', last_name: '', email: '', phone: '', guest: true,
          }}
          validationSchema={GroupFormSchema}
          onSubmit={async (values, actions) => {
            actions.setSubmitting(false);
            await axios.post('https://www.grupy-radosci.pl/api/v1/participants', {
              ...values, phone: values.phone.replace(' ', '').replace('-', '')
            }).then(r => {
              console.log(r)
              if (r.status === 200 || r.status === 201) {
                setSuccess(true);
              } else {
                setError(true)
              }
              actions.setSubmitting(true)
            }).catch(error => {
              console.log(error.response.status)
              if (error.response.status === 408) {
                setDisabled(true);
              }
              setError(true);
            })
          }}
        >
          <Form>
            <FormContainer>
              <Field name="first_name">
                {({field, meta}) => (<FieldContainer>
                  <TextField id="first_name" label="Imię" variant="outlined" size='medium' fullWidth {...field} />
                  {meta.touched && meta.error && (<ErrorText>{meta.error}</ErrorText>)}
                </FieldContainer>)}
              </Field>
              <Field name="last_name">
                {({field, meta}) => (<FieldContainer>
                  <TextField id="last_name" label="Nazwisko" variant="outlined" size='medium' fullWidth {...field} />
                  {meta.touched && meta.error && (<ErrorText>{meta.error}</ErrorText>)}
                </FieldContainer>)}
              </Field>
              <Field name="email">
                {({field, meta}) => (<FieldContainer>
                  <TextField id="email" label="Email" variant="outlined" size='medium' type="email" fullWidth {...field} />
                  {meta.touched && meta.error && (<ErrorText>{meta.error}</ErrorText>)}
                </FieldContainer>)}
              </Field>
              <Field name="phone">
                {({field, meta}) => (<FieldContainer>
                  <TextField id="phone" label="Numer telefonu" variant="outlined" size='medium' type="text"
                             fullWidth {...field} />
                  {meta.touched && meta.error && (<ErrorText>{meta.error}</ErrorText>)}
                </FieldContainer>)}
              </Field>
              {/*<Field name="guest">*/}
              {/*  {({field, meta}) => (*/}
              {/*    <FieldContainer>*/}
              {/*    <FormControl fullWidth>*/}
              {/*      <div role="group" aria-labelledby="my-radio-group">*/}
              {/*        <label>*/}
              {/*          <Field type="radio" name="picked" value="One" />*/}
              {/*          One*/}
              {/*        </label>*/}
              {/*        <label>*/}
              {/*          <Field type="radio" name="picked" value="Two" />*/}
              {/*          Two*/}
              {/*        </label>*/}
              {/*      </div>*/}
              {/*      /!*<FormLabel id="guest">Tekst</FormLabel>*!/*/}
              {/*      /!*<RadioGroup*!/*/}
              {/*      /!*  defaultValue="true"*!/*/}
              {/*      /!*  name="guest"*!/*/}
              {/*      /!*>*!/*/}
              {/*      /!*  <FormControlLabel value="true" onChange={() => field.setValue(true)} control={<Radio value='true'/>}*!/*/}
              {/*      /!*                    label="Zapisuję się na jedno spotkanie"/>*!/*/}
              {/*      /!*  <FormControlLabel value="false" onChange={() => field.setValue(false)} control={<Radio value='false'/>} label="Zapisuję się na stałe"/>*!/*/}
              {/*      /!*</RadioGroup>*!/*/}
              {/*    </FormControl>*/}
              {/*  </FieldContainer>)}*/}

              <FieldContainer>
                <FormControl fullWidth>
                  <div role="group" aria-labelledby="my-radio-group">
                    <label>
                      <Field type="radio" name="guest" value="true"/>
                      <span style={{marginLeft: '1rem'}}></span>
                      Zapisuję się na jedno spotkanie
                    </label>
                    <br/>
                    <br/>
                    <label>
                      <Field type="radio" name="guest" value="false"/>
                      <span style={{marginLeft: '1rem'}}></span>
                      Zapisuję się na stałe
                    </label>
                  </div>
                </FormControl>
              </FieldContainer>
              {/*</Field>*/}
              <Button type="submit" variant="outlined" size='large'>Zapisz się</Button>
            </FormContainer>
          </Form>
        </Formik>)}
      </Container>
    </ThemeProvider>)}
  ></StaticQuery>);
};

export default GroupFormPage;
